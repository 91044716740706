
/* Icons
-----------------------------------------------------------------------------*/

@font-face {
    font-family: 'icons';
    src: url(icons.woff2) format('woff2'),
		url(icons.woff) format('woff');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	speak: never;
	display: inline;
	text-decoration: inherit;
	text-align: center;
	text-transform: none;
	line-height: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-circle-facebook:before { content: '\e800'; } /* '' */
.icon-circle-instagram:before { content: '\e801'; } /* '' */
.icon-circle-twitter:before { content: '\e802'; } /* '' */
.icon-circle-spotify:before { content: '\e803'; } /* '' */
.icon-payout:before { content: '\e804'; } /* '' */
.icon-review:before { content: '\e805'; } /* '' */
.icon-record:before { content: '\e806'; } /* '' */
.icon-faq:before { content: '\e807'; } /* '' */
.icon-users:before { content: '\e808'; } /* '' */
.icon-plane:before { content: '\e809'; } /* '' */
.icon-artist:before { content: '\e80a'; } /* '' */
.icon-network:before { content: '\e80b'; } /* '' */
.icon-chat:before { content: '\e80c'; } /* '' */
.icon-email:before { content: '\e80d'; } /* '' */
.icon-audio:before { content: '\e80e'; } /* '' */
.icon-headphones:before { content: '\e80f'; } /* '' */
.icon-bulb:before { content: '\e810'; } /* '' */
.icon-soundcloud:before { content: '\e811'; } /* '' */
.icon-spotify:before { content: '\e812'; } /* '' */
.icon-support:before { content: '\e814'; } /* '' */
.icon-earnings:before { content: '\e815'; } /* '' */
.icon-affiliates:before { content: '\e816'; } /* '' */
.icon-reviews:before { content: '\e817'; } /* '' */
.icon-playlists:before { content: '\e818'; } /* '' */
.icon-campaigns:before { content: '\e819'; } /* '' */
.icon-search:before { content: '\e81a'; } /* '' */
