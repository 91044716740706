// Songrocket Colors
$pink: #b0f;

$primary: #F4511E;
$secondary: #bb00ff;
$accent: #8c9eff;
$error: #b71c1c;
$darkGrey: #adb5bd;

@media screen and (min-width: 640px) {
    .w-md-50 {
        width: 50% !important;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 640px) {
    h1.songrocket-h1 {
        font-size:2.6rem !important;
    }
}

h1.songrocket-h1 {
    font-size:3.6rem;
    font-family: 'HelveticaNowDisplay', sans-serif
}

p.songrocket-description {
    color:grey;
    font-family: 'HelveticaNowDisplay', sans-serif
}

.btn-songrocket {
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: middle;
    min-width: 200px;
    font-size: 12px;
    font-weight: 800;
    line-height: 1em;
    letter-spacing: .15em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    color: #fff;
    margin: 0;
    padding: 18px 28px;
    border: 1px solid #b0f;
    border-radius: 25px;
    touch-action: manipulation;
    white-space: nowrap;
    outline: 0;
    transition: all .2s ease;

    &:hover, &:active {
        background-color:white;
        color: $pink !important;
    }
}

.btn-pink {
    background-color: $pink;
}

a.link-songrocket {
    color: $pink !important;

    &:hover {
        text-decoration:none;
        color: $red !important;
    }

}

.form-control {
    background-color:black;
}
.form-rounded {
    border-radius: 1rem;
}

.songrocket-input {
    display: block;
    background-color: #262626 !important;
    color: #fff;
    text-align: center;
    border: 0;
    border-radius: 28px;
    box-shadow: none;
    outline: 0;
    width: 100%;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 400;
    height: 56px;
}

.form-control::placeholder {
    color: #fff;
    opacity: 1;
}

.form-control {
    &:focus {
        color:#fff;
    }
}

.auth-background {
    /* The image used */
    background-image: url("../../public/assets/signin-bg2.jpg");

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

a.arrow-prev {
    margin: 1em 0;
    color:white !important;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: .15em;
    text-transform: uppercase;

    text-decoration: none;
    transition: color .3s ease;

    &::after {
        display: inline-block;
        padding-left: 8px;
        font-size: 24px;
        content: "\2192"; // arrow right unicode
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        color: $red !important;
        text-decoration: none;

        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }


}

a.arrow-next {
    margin: 1em 0;
    color:white !important;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: .15em;
    text-transform: uppercase;

    text-decoration: none;
    transition: color .3s ease;

    &::before {
        display: inline-block;
        padding-right: 8px;
        font-size: 24px;
        content: "\2190"; // arrow right unicode
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        color: $red !important;
        text-decoration: none;

        &::before {
            -webkit-transform: translateX(-4px);
            -moz-transform: translateX(-4px);
            -ms-transform: translateX(-4px);
            -o-transform: translateX(-4px);
            transform: translateX(-4px);
        }
    }

}

/**
 * Custom Styling VueJs Dialog
**/

.dg-main-content {
    background-color: #0d161f !important;
}
.dg-content-body {
    border-bottom: 0px !important;
}

.dg-btn--ok {
    color: #fff !important;
    background-color: $orange !important;
    border-color: $orange !important;
}

.dg-btn--cancel {
    color: #fff !important;
    background-color: $red !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.stepper-background {
    background: #303030 !important;
}

.vue-slider-process {
    background: #F4511E !important;
}

.vue-slider-dot-tooltip-inner {
    border-color: #F4511E !important;
    background-color: #F4511E !important;
}

.spotify-color {
    color: #84BD00 !important;
}

a {
    text-decoration: none;
}

/**
 * Vue Multiselect Custom styling
 */
.multiselect__tags {
    border: 1px solid #ffffff;
    background: transparent !important;
}
.multiselect__single {
    background: transparent !important;
    color:#ffffff !important;
}
.multiselect__input {
    background: transparent !important;
    color: #fff !important;
}
.multiselect {
    color: #fff !important;
}
.multiselect__content {
    background: #303030;
    padding-left:0;
}
.multiselect__content-wrapper {

}
.v-application ol, .v-application ul {
    padding-left: 0;
    color:#fff;
}
.multiselect__input::placeholder {
    color:#fff !important;
}
.multiselect__tag-icon:after {
    content: "\D7" !important;
    color: #fff !important;
    font-size: 14px !important;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: $primary !important;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after,
.multiselect__option--selected.multiselect__option--highlight:after,
.multiselect__option--selected.multiselect__option--highlight {
    background: $primary !important;
    color: #fff !important;
}
.multiselect__option--selected {
    background: transparent !important;
    color: #fff !important;
    font-weight: 700 !important;
}
.multiselect__option--selected.multiselect__option--highlight {
    background: $primary !important;
    color: #fff !important;
}
.multiselect__tag {
    background: $primary !important;
}
