
/* Fonts
-----------------------------------------------------------------------------*/

@font-face {
	font-family: 'HelveticaNowDisplay';
	src: url(hnd-light.woff2) format('woff2'),
		url(hnd-light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNowDisplay';
	src: url(hnd-regular.woff2) format('woff2'),
		url(hnd-regular.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNowDisplay';
	src: url(hnd-bold.woff2) format('woff2'),
		url(hnd-bold.woff) format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNowDisplay';
	src: url(hnd-extrabold.woff2) format('woff2'),
		url(hnd-extrabold.woff) format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
